<template>
  <div>
    <b-table-simple class="table">
      <thead>
        <tr>
          <td class="td-head">
            <Check
              v-if="selectedItemsCount > 0"
              :value="true"
              :tooltip="`Desselecionar tudo (${selectedItemsCount})`"
              @change="clearSelection"
            />
          </td>
          <td class="td-head">Data do agend.</td>
          <td class="td-head">Item</td>
          <td class="td-head">Convênio</td>
          <td class="td-head">Profissional/Sala</td>
          <td class="td-head">Valor</td>
          <td class="td-head"></td>
        </tr>
      </thead>
      <tbody v-if="!loading">
        <tr v-for="pendingItem in pending" :key="pendingItem.id">
          <td>
            <Check
              :value="!!selectedItems[pendingItem.id]"
              :disabled="disableCheckbox(pendingItem)"
              :tooltip="getTooltip(pendingItem)"
              @change="checked => onChangeCheck(pendingItem, checked)"
            />
          </td>
          <td>{{ parseDate(pendingItem.appointment.start_datetime) }}</td>
          <td 
            :class="{ 'deleted-item': pendingItem?.item?.deleted_at }"
          >
            <span class="type">{{ parseType(pendingItem) }} | </span>
            {{ pendingItem?.item?.name }}
          </td>
          <td>{{ pendingItem.health_plan.fantasy_name }}</td>
          <td>{{ parseProfessionalName(pendingItem.appointment.professional ? pendingItem.appointment.professional?.name : pendingItem.appointment.room?.name) }}</td>
          <td>{{ pendingItem?.health_plan?.clinic_health_plan[0]?.invoicing_tiss_active ? '-' : (userHasPermission('CcProc6') ? parseNumberToMoney(pendingItem.value) : '-') }}</td>
          <td>
            <div class="actions">
              <Icon tooltip="Descartar pendência">
                <Delete class="pointer" @click="discardPendingItem(pendingItem)" />
              </Icon>
            </div>
          </td>
        </tr>
      </tbody>
    </b-table-simple>

    <div v-if="(!loading && !pending.length)" class="empty">
      Nenhuma pendência por aqui
    </div>

    <div v-if="loading">
      <div class="skeleton"><b-skeleton animation="fade" width="100%"></b-skeleton></div>
      <div class="skeleton"><b-skeleton animation="fade" width="100%"></b-skeleton></div>
      <div class="skeleton"><b-skeleton animation="fade" width="100%"></b-skeleton></div>
      <div class="skeleton"><b-skeleton animation="fade" width="100%"></b-skeleton></div>
      <div class="skeleton"><b-skeleton animation="fade" width="100%"></b-skeleton></div>
    </div>

  </div>
</template>
<script>
import { parseType } from '@/utils/itemHelper'
import { parseNumberToMoney } from '@/utils/moneyHelper'
import Icon from '@/components/General/Icon'
import Check from '@/components/General/Check'
import Delete from '@/assets/icons/delete.svg'
import { userHasPermission } from '@/utils/localStorageManager'

export default {
  components: { Icon, Check, Delete },
  props: {
    pending: Array,
    loading: Boolean,
    checkedItems: Array,
  },
  computed: {
    selectedItemsCount: function() {
      return Object.keys(this.selectedItems).filter(key => this.selectedItems[key]).length
    }
  },
  data() {
    return {
      selectedItems: {},
      selectedHealthPlan: null,
    }
  },
  methods: {
    userHasPermission,
    parseType,
    parseNumberToMoney,
    clearSelection() {
      this.selectedItems = {}
      this.selectedHealthPlan = null
      this.$emit('checked', [])
    },
    onChangeCheck(pendingItem, checked) {
      this.selectedItems = {
        ...this.selectedItems,
        [pendingItem.id]: checked ? pendingItem : null
      }
      this.selectedHealthPlan = pendingItem.health_plan_id
      if (Object.keys(this.selectedItems).every(key => !this.selectedItems[key])) {
        this.selectedHealthPlan = null
      }
      const selectedItems = Object.keys(this.selectedItems)
        .filter(key => this.selectedItems[key])
        .map(key => this.selectedItems[key])

      this.$emit('checked', selectedItems)
    },
    getTooltip(pendingItem) {
      if (pendingItem?.item?.deleted_at)
        return 'Item excluído'
      else if (this.selectedHealthPlan && this.selectedHealthPlan !== pendingItem.health_plan_id)
        return 'Você só pode selecionar itens do mesmo convênio de uma vez'
      return null
    },
    disableCheckbox(pendingItem) {
      return !!pendingItem?.item?.deleted_at ||
        (this.selectedHealthPlan && this.selectedHealthPlan !== pendingItem.health_plan_id)
    },
    discardPendingItem(pendingItem) {
      this.$swal({
        icon: 'question',
        html: `<div class="body-alert">Deseja realmente descartar a pendência <span>${pendingItem.item?.name}</span>? O item não será removido do agendamento.</div>`,
        showCancelButton: true,
        confirmButtonColor: '#F63220',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
       }).then(res => {
        if (res.isConfirmed) {
          this.$emit('discardPendingItem', pendingItem.id)
        }
      })
    },
    parseDate(datetime) {
      return this.moment(datetime).format('DD/MM/YYYY')
    },
    parseProfessionalName(name) {
      if (!name) return '-'
      const parts = name.split(' ')
      if (parts.length < 2) return parts[0]
      return `${parts[0]} ${parts[parts.length - 1]}`
    },
  },
  watch: {
    checkedItems(value, old) {
      if (!value.length && old.length) {
        this.clearSelection()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.table {
  margin: 20px 0;
  thead {
    td {
      &:first-child {
        border-radius: 8px 0 0 0;
      }
      &:last-child {
        border-radius: 0 8px 0 0;
      }
    }
  }
  tr, td {
    padding: 10px 6px;
    font-size: 0.8rem;
    vertical-align: middle;
    color: var(--type-active);
  }
  .td-head {
    color: var(--dark-blue);
    font-weight: 700;
    font-size: 14px;
    border: none;
    background-color: var(--neutral-100);
  }
  .deleted-item {
    text-decoration: line-through;
  }
  .type {
    color: var(--blue-500);
    font-weight: 600;
  }
  .actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;

    .pointer {
      cursor: pointer;
    }
  }
}

.empty {
  width: 100%;
  text-align: center;
  padding: 10px;
  font-size: 12px;
  color: var(--type-active);
}
.skeleton {
  width: 100%;
  padding: 6px;
}
</style>
